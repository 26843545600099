/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

/* COLORS */
$color-primary:     #434343;
$color-secondary:   #95C028;
$color-tertiary:		#4a4a4a;

$color-link:        #285a64;
$color-link-hover:  #434343;

$color-link2:       #eb6b4a;
$color-link2-hover: #c2573c;

$color-error:				#e00000;

$white:							#fff;
$black:							#000;
$red:								#eb6b4a;
$lightblue:					#94dbe7;
$grey:							#9d9d9d;
$darkgrey:					#333333;
$lightgrey:					#dadada;
$cyan:							#285a63;
$topfootergrey:			#a1a1a1;
$middlefootergrey:	#969696;
$bottomfootergrey:	#6d6d6d;
$socialicongrey:		#c5c7c8;
$yellow:						#ffd200;
$lightyellow:				#ffeea1;
$middlegrey:				#7d7d7d;
$middlegreylight:		#b3b3b3;
$link_blue:					#337ab7;
$link_blue_light:		#A5C9FF;
$greyblue: 					#cdd9db;

$net_zero_green: #92D560;
$net_zero_blue: #052e59;
$net_zero_yellow: #ffdc48;

input, textarea, button, select, label, a {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

.white_bg { background: $white; } .white_text { color: $white; } .white_color { color: $white; }
.black_bg { background: $black; } .black_text { color: $black; }
.red_bg { background: $red; } .red_text { color: $red; }
.yellow_bg { background: $yellow; } .yellow_text { color: $yellow; }
.net_zero_yellow_bg { background: $net_zero_yellow; } .net_zero_yellow_text { color: $net_zero_yellow; }
.grey_bg { background: #dadada; } .grey_text { color: $grey; }
.darkgrey_bg { background: $darkgrey; } .darkgrey_text { color: $darkgrey; }
.lightgrey_bg { background: $lightgrey; } .lightgrey_text { color: $lightgrey; }
.lightyellow_bg { background: $lightyellow; } .lightyellow_text { color: $lightyellow; }
.cyan_bg { background: $cyan; } .cyan_text, .tuerkis { color: $cyan; }
.net_zero_blue_bg { background: $net_zero_blue; } .net_zero_blue_text { color: $net_zero_blue; }
.bottomfootergrey_bg { background: $bottomfootergrey; } .bottomfootergrey_text { color: $bottomfootergrey; }
.middlegrey_bg { background: $middlegrey; } .middlegrey_color { color: $middlegrey; }
.greyblue_bg { background: $greyblue; } .greyblue_text { color: $greyblue; }
.green_bg { background: $color-secondary; }
.net_zero_green_bg { background: $net_zero_green; }
