/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

/* BACKGROUND OPACITY */
@mixin color-opacity($property, $color, $opacity) {
    $property: $color;
    $property: rgba($color, $opacity);
}

/* BREAKPOINTS */
@mixin breakpoint($point_max, $point_min:"") {
  @if $point_max == desktop {
    @media only screen and (max-width: $desktop) { @content ; }
  } @else if $point_max == tablet {
    @media only screen and (max-width: $tablet) { @content ; }
  } @else if $point_max == tablet_l {
    @media only screen and (max-width: $tablet_l) { @content ; }
  } @else if $point_max == tablet_p {
    @media only screen and (max-width: $tablet_p) { @content ; }
  } @else if $point_max == smartphone {
    @media only screen and (max-width: $smartphone)  { @content ; }
  } @else if $point_max == smartphone_p {
      @media only screen and (max-width: $smartphone_p)  { @content ; }
  }@else {
    @media only screen and (max-width: $point_max) { @content ; }
  }
}


/* FONT FACE */
@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
           url('#{$file-path}.woff') format('woff'),
           url('#{$file-path}.woff2') format('woff2'),
           url('#{$file-path}.ttf') format('truetype'),
           url('#{$file-path}.svg#{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}


/* HOVERS */
/* Create pseduo-classes for `:hover`, `:active`, and `:focus`. */
/* @usage - `@include hover` */
@mixin hover {
  &:hover,
  &:active,
  &:focus { @content; }
}


/* OPACITY */
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity-ie)";
  filter: alpha(opacity=$opacity-ie);
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  opacity: $opacity;
}


/* PX TO REM */
@mixin rem($property, $values) {
  $px : ();
  $rem: ();

  @each $value in $values {

    @if $value == 0 or $value == auto {
      $px : append($px, $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);
      $val: parseInt($value);

      @if $unit == "px" {
        $px : append($px,  $value);
        $rem: append($rem, ($val / 10 + rem));
      }

      @if $unit == "rem" {
        $px : append($px,  ($val * 10 + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}


/* RETINA IMAGES */
@mixin retina-image($filename, $background-size, $extension: png, $retina-filename: null, $retina-suffix: _2x, $asset-pipeline: $asset-pipeline) {
  @if $asset-pipeline {
    background-image: image-url("#{$filename}.#{$extension}");
  } @else {
    background-image:       url("#{$filename}.#{$extension}");
  }

  @include hidpi {
    @if $asset-pipeline {
      @if $retina-filename {
        background-image: image-url("#{$retina-filename}.#{$extension}");
      } @else {
        background-image: image-url("#{$filename}#{$retina-suffix}.#{$extension}");
      }
    } @else {
      @if $retina-filename {
        background-image: url("#{$retina-filename}.#{$extension}");
      } @else {
        background-image: url("#{$filename}#{$retina-suffix}.#{$extension}");
      }
    }

    background-size: $background-size;
  }
}
