
/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

/* PATHS */
$image_path:							'../../images/';

/* BREAKPOINTS */
$smartphone_p:						480px;
$smartphone:							767px;
$tablet_p:								768px;
$tablet_l:								1040px;
$tablet:  								1366px;
$desktop: 								2048px;

/* FONT SIZES */
$html-font-size:						62.5%;

$base-font-size:						16px;
$base-line-height: 					22px;
$base-text-transform:				none;
$base-letter-spacing:				0.025em;

$font-size-h1:							38px;
$line-height-h1:						48px;
$text-transform-h1:					uppercase;
$letter-spacing-h1:					0.10em;

$font-size-h2:							32px;
$line-height-h2:						38.4px;
$text-transform-h2:					none;
$letter-spacing-h2:					0.10em;

$font-size-h3:							26px;
$line-height-h3:						31.2px;
$text-transform-h3:					none;
$letter-spacing-h3:					0.10em;

$font-size-h4:							24px;
$line-height-h4:						34px;
$text-transform-h4:					none;
$letter-spacing-h4:					0.1em;

$font-size-h5:							18px;
$line-height-h5:						24px;
$text-transform-h5:					uppercase;
$letter-spacing-h5:					0.10em;

$font-size-h6:							14px;
$line-height-h6:						16.8px;
$text-transform-h6:					none;
$letter-spacing-h6:					0.1em;

$font-size-header-menu:					14px;
$line-height-header-menu:				17px;
$text-transform-header-menu:		uppercase;
$letter-spacing-header-menu:		0.15em;

$font-size-header-submenu:				14px;
$line-height-header-submenu:			18px;
$text-transform-header-submenu:		uppercase;
$letter-spacing-header-submenu:		0.25rem;

$font-size-header-menu-mobile:			18px;
$line-height-header-menu-mobile:		21px;
$text-transform-header-menu-mobile:	none;
$letter-spacing-header-menu-mobile:	0em;

$font-size-header-submenu-mobile:       15px;
$line-height-header-submenu-mobile:     18px;
$text-transform-header-submenu-mobile:  none;
$letter-spacing-header-submenu-mobile:	0em;

$font-size-footer-menu:					16px;
$line-height-footer-menu:				22px;
$text-transform-footer-menu:		none;
$letter-spacing-footer-menu:		0.1em;

$font-size-footer-submenu:				10px;
$line-height-footer-submenu:			12px;
$text-transform-footer-submenu:		none;
$letter-spacing-footer-submenu:		0em;

/* GRID */
$grid-width:							940px;
$gutter-width:						20px;
$outer-gutter-width:			70px;

/* DESKTOP */
$header-height:						80px;
$logo-width:							185px;
$logo-height:             54px;

/* MOBILE */
$mobile-logo-width:						          110px;
$mobile-menubutton-outer-padding-ipad:  40px;
$mobile-menubutton-outer-padding-phone: 20px;
$mobile-bar-height:                     40px;
$mobile-menu-button-height:				      18px;
$mobile-menu-button-width: 				      20px;
$mobile-menu-bar-height: 				        3px;
$mobile-menu-bar-gutter-horizontal: 	  7px;
$mobile-menu-bar-gutter-vertical:		    7px;

/* FORMS */
$input-height:	40px;
$search-height:	20px;

/* SLIDESHOW */
$arrow-width:         45px;
$arrow-height:        84px;
$arrow-width-mobile:  22.5px;
$arrow-height-mobile: 42px;
